
import { defineComponent } from "vue-demi";
import ProflieMain from "@/components/profile/main/index.vue";

export default defineComponent({
  name: "ProflieView",
  components: {
    ProflieMain,
  },
});
