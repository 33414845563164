import { User } from "@/interfaces/user/user.dto";

const cities = [
  { _id: 'Москва', name: 'Москва' },
  { _id: 'Новосибирск', name: 'Новосибирск' },
]

export const PROFILE_INPUTS = (user: User.Dto) => ({
  inputs: [
    { show: true, id: 'name', name: 'ФИО', placeholder: 'Введите ФИО', type: 'text', grid: '1 / 11' },
    { show: true, id: 'city', name: 'Город', placeholder: 'Выберите город', type: 'text', grid: '1 / 11', drop: true, showDrop: false, dropData: cities, },
    { show: true, id: 'phone', name: 'Телефон', placeholder: 'Введите телефон', type: 'text', mask: '+7 (###) ###-##-##', grid: '1 / 11' },
    { show: true, id: 'email', name: 'Email', placeholder: 'Введите Email', type: 'email', grid: '1 / 11' },
  ],
  data: {
    name: user.name,
    phone: user.phone,
    city: user.city,
    email: user.email,
  }
});

export const PROFILE_PASSWORD_INPUTS = {
  inputs: [
    { show: true, id: 'old_password', name: 'Старый пароль', placeholder: '*******', type: 'password', grid: '1 / 11' },
    { show: true, id: 'new_password', name: 'Новый пароль', placeholder: '*******', type: 'password', grid: '1 / 11' },
    { show: true, id: 'repeat_password', name: 'Повторите новый пароль', placeholder: '*******', type: 'password', grid: '1 / 11' },
  ],
  data: {
    old_password: '',
    new_password: '',
    repeat_password: '',
  }
}

const types = [
  { _id: 'Юридическое лицо', name: 'Юридическое лицо' },
  { _id: 'Самозанятый', name: 'Самозанятый' },
]

export const PROFILE_FINANCE_INPUTS = (finance?: User.Finance) => ({
  inputs: [
    { show: true, id: 'type', name: 'Юридическое лицо или самозанятый', placeholder: 'Выберите тип', type: 'text', grid: '1 / 6', drop: true, showDrop: false, dropData: types },
    { show: true, id: 'name', name: 'Название компании', placeholder: 'Введите название', type: 'text', grid: '6 / 11' },
    { show: true, id: 'INN', name: 'ИНН', placeholder: 'Введите ИНН', type: 'text', grid: '1 / 6', mask: ['##########', '############'] },
    { show: true, id: 'BIK', name: 'БИК банка', placeholder: 'Введите БИК банка', type: 'text', grid: '6 / 11', mask: '#########' },
    { show: true, id: 'account', name: 'Номер счета', placeholder: 'Введите номер счета', type: 'text', grid: '1 / 6', mask: '####################' },
    { show: true, id: 'address', name: 'Адрес', placeholder: 'Введите адрес', type: 'text', grid: '6 / 11' },
  ],
  data: {
    name: finance?.name || '',
    type: finance?.type || '',
    INN: finance?.INN || null,
    BIK: finance?.BIK || null,
    account: finance?.account || null,
    address: finance?.address || '',
  }
});

export const PROFILE_FINANCE_WITHDRAWAL_INPUTS = () => ({
  inputs: [
    { show: true, id: 'amount', name: 'Сколько хотите вывести?', placeholder: 'Введите сумму', type: 'number', grid: '1 / 11' },
  ],
  data: {
    amount: '',
  }
});
