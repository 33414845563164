
import { defineComponent, PropType } from "vue-demi";
import { File } from "@/interfaces/file/file.dto";
import { EditIcon, TrashIcon, EmptyImageIcon } from "@/plugins/icons";
import { checkFileSize, checkImageFiles } from "@/utils/files";

export default defineComponent({
  emits: ['updateAvatar', 'deleteAvatar'],
  name: "ProfileAvatar",
  props: {
    avatar: {
      type: Object as PropType<File.Dto>,
    },
  },
  methods: {
    uploadFile() {
      const input = (this.$refs.file as HTMLInputElement);
      if (!input || !input.files) return;
      if (!checkFileSize(input.files)) return;
      if (!checkImageFiles(input.files)) return;

      const formData = new FormData();
      formData.append('file', input.files[0]);
      this.$emit('updateAvatar', formData);
    },
  },
  components: {
    EditIcon,
    TrashIcon,
    EmptyImageIcon,
  },
});
